import { useEffect } from "react";
import { Helmet } from "react-helmet";

import type { UsersOrderData } from "@/types";
import OrderCard from "@/components/Profile/OrderCard";
import EmptyOrdersMessage from "@/components/Profile/EmptyOrdersMessage";
import { useStateContext } from "@/context/ContextProvider";
import {
	refundOrderByChargeId,
	getOrdersByUserId,
	updateOrderById,
} from "@/utils/api";

const OrdersAndSubscriptions = () => {
	const { usersOrderData, setUsersOrderData, loggedInUser, loggedInUserToken } =
		useStateContext()!;

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const orders = await getOrdersByUserId(
					loggedInUser.id,
					loggedInUserToken,
				);
				if (!orders) return;

				const sortedOrders = orders.sort(
					(
						a: { createdAt: string | number | Date },
						b: { createdAt: string | number | Date },
					) =>
						new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
				);
				setUsersOrderData(sortedOrders);
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		};

		fetchOrders();
	}, []);

	const activeOrders = usersOrderData.filter((order) => !order.cancelled);
	const cancelledOrders = usersOrderData.filter((order) => order.cancelled);

	const isOrderCancelable = (order: UsersOrderData) => {
		const currentDateTime = new Date();
		const collectionDateTime = new Date(order.collectionDate);

		// Calculate the date one day before the collection date
		const oneDayBeforeCollectionDate = new Date(collectionDateTime);
		oneDayBeforeCollectionDate.setDate(
			oneDayBeforeCollectionDate.getDate() - 1,
		);

		return currentDateTime < oneDayBeforeCollectionDate && !order.cancelled;
	};

	const handleCancelOrder = async (orderId: number, latestChargeId: string) => {
		try {
			const refundedOrder = await refundOrderByChargeId(
				latestChargeId,
				loggedInUserToken,
				loggedInUser,
			);
			const updatedOrder = await updateOrderById(
				orderId,
				{ cancelled: true },
				loggedInUserToken,
			);

			if (updatedOrder && refundedOrder) {
				setUsersOrderData((prevOrders) =>
					prevOrders.filter((order) => order.id !== updatedOrder.id),
				);
				window.location.reload();
			}
		} catch (error) {
			console.log(
				"%cOrdersAndSubscriptions.tsx line:34 error",
				"color: #26bfa5;",
				error,
			);
		}
	};

	return (
		<div className="prose w-full p-4">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Your Orders | Laundro</title>
				<meta
					name="description"
					content="You can find all of your previous and current orders here! As well as manage any subscriptions you have. You can easily order a previous order again too!"
				/>
				<link
					rel="canonical"
					href="https://laundro.co.uk/orders-and-subscriptions"
				/>
			</Helmet>

			{usersOrderData.length ? (
				<div className="flex flex-col gap-10">
					<h1 className="text-center">Your Orders 🎉</h1>

					{activeOrders.map((order) => (
						<OrderCard
							key={order.id}
							order={order}
							onCancelOrder={
								isOrderCancelable(order) ? handleCancelOrder : undefined
							}
							isCancelled={false}
						/>
					))}

					{cancelledOrders.length > 0 && (
						<>
							<h2 className="-mb-6">Cancelled Orders</h2>
							{cancelledOrders.map((order) => (
								<OrderCard key={order.id} order={order} isCancelled={true} />
							))}
						</>
					)}
				</div>
			) : (
				<EmptyOrdersMessage />
			)}
		</div>
	);
};

export default OrdersAndSubscriptions;
