interface OrderNotesProps {
	notes: string;
}

const OrderNotes = ({ notes }: OrderNotesProps) => {
	return (
		<div>
			<div className="divider" />
			<p className="text-xl italic">
				<span className="font-bold">Notes:</span> {notes}
			</p>
			<div className="divider" />
		</div>
	);
};

export default OrderNotes;
