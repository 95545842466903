import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "@/components/ui/sheet";

import { useStateContext } from "@/context/ContextProvider";
import { useState } from "react";
import { Link } from "react-router-dom";
import ShoppingCart from "./ShoppingCart";
import Avatar from "./Avatar";
import { endpoints } from "../constants/endpoints";
import { isUserAnAdmin } from "../utils/isUserAnAdmin";
import LaundroFullLogoSVG from "../assets/SVG/LaundroFullLogoSVG";
import { NavigationMenu, NavigationMenuList } from "./ui/navigation-menu";
import { useScroll } from "@/hooks/useScroll";

const Drawer = () => {
	const context = useStateContext();
	const hasScrolled = useScroll();

	const [activeTab, setActiveTab] = useState("");

	const handleTabClick = (endpoint: string) => {
		setActiveTab(endpoint);
	};

	if (!context) return null;

	return (
		<nav
			className={`fixed top-0 left-0 w-full py-2 px-4 z-50 transition-all duration-300 ${
				hasScrolled ? "bg-base-200 shadow-md opacity-90" : "bg-transparent "
			}`}
		>
			<Sheet>
				<div className="flex justify-between items-center">
					<SheetTrigger>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h8m-8 6h16"
							/>
						</svg>
					</SheetTrigger>

					<div className="flex justify-center items-center">
						<ShoppingCart />

						<NavigationMenu>
							<NavigationMenuList>
								<Avatar />
							</NavigationMenuList>
						</NavigationMenu>
					</div>
				</div>

				<SheetContent className="bg-base-300" side={"left"}>
					<SheetHeader>
						<SheetTitle className="text-base-100">
							<LaundroFullLogoSVG />
						</SheetTitle>
					</SheetHeader>

					{isUserAnAdmin(context.loggedInUser) ? (
						<ul className="menu p-4 w-80 min-h-full bg-base-100 text-base-content">
							<li>
								<Link
									to={"/dashboard"}
									className={`text-xl font-semibold ${
										activeTab === "/dashboard" ? "btn-primary" : ""
									}`}
									onClick={() => handleTabClick("/dashboard")}
								>
									Dashboard
								</Link>
							</li>
						</ul>
					) : (
						<ul className="menu text-base-content">
							{endpoints.map(({ endpoint, page }) => {
								return (
									<li className="rounded-lg" key={endpoint}>
										<SheetClose asChild>
											<Link
												to={endpoint}
												className={`text-xl font-semibold ${
													activeTab === endpoint ? "btn-primary" : ""
												}`}
												onClick={() => handleTabClick(endpoint)}
											>
												{page}
											</Link>
										</SheetClose>
									</li>
								);
							})}
						</ul>
					)}
				</SheetContent>
			</Sheet>
		</nav>
	);
};

export default Drawer;
