import type { JSX } from "react/jsx-runtime";

const LaundroIconSVG = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
	return (
		<svg
			aria-labelledby="laundroIcon"
			id="Layer_2"
			data-name="Layer 2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 568.37 525.3"
			className="w-10 h-10"
			{...props}
		>
			<title id="laundroIcon">Laundro Icon</title>
			<path d="m18.04,103.84c-27.13-9.4-22.48-49.11,6.09-51.98l220.02-22.09L538.85.14c20.52-2.06,35.58,18.86,27.11,37.66l-121.67,270.05-90.81,201.62c-11.79,26.18-50.93,17.99-51.22-10.73l-2.05-198.89c-.04-3.77-3.06-6.83-6.83-6.92l-58.16-1.3,191.73-178.52c2.71-2.52-.4-6.84-3.65-5.07l-230.16,125.12,17.24-55.57c1.12-3.6-.82-7.44-4.39-8.68L18.04,103.84Z" />
		</svg>
	);
};

export default LaundroIconSVG;
