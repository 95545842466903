import { useStateContext } from "../context/ContextProvider";
import {
	findAddressByPostcode,
	getOrdersByUserId,
	updateUser,
} from "../utils/api";
import AddAddressModal from "../components/AddAddressModal";
import { checkUserHasAnAddress } from "../utils/checkUserHasAnAddress";
import EditableField from "../components/EditableField";
import { toastNotification } from "../utils/toastNotification";
import { useEffect, useState } from "react";
import type { UsersOrderData } from "../types";
import availablePostcodes from "../data/availablePostcodes.json";

const Profile = () => {
	const {
		loggedInUser,
		loggedInUserToken,
		setLoggedInUser,
		setUsersOrderData,
	} = useStateContext()!;
	const [activeSection, setActiveSection] = useState<
		"Your Details" | "Your Stats" | null
	>("Your Details");

	const toggleAccordion = (
		sectionName: "Your Details" | "Your Stats" | null,
	) => {
		setActiveSection(sectionName === activeSection ? null : sectionName);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const orders: UsersOrderData[] = await getOrdersByUserId(
					loggedInUser.id,
					loggedInUserToken,
				);

				setUsersOrderData(orders);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, []);

	const isSectionActive = (sectionName: string | null) =>
		sectionName === activeSection;

	const handleSaveField = async (
		field: string,
		newValue: string | undefined,
	) => {
		try {
			let updatedValue = newValue;

			//   POSTCODE VALIDATION
			if (field === "postcode" && newValue) {
				const address = await findAddressByPostcode(newValue);

				if (
					address.header.query.toLocaleLowerCase().replace(" ", "").trim() ===
					address.results[0].GAZETTEER_ENTRY.ID.toLocaleLowerCase()
				) {
					if (
						availablePostcodes.includes(
							newValue.replace(" ", "").toLocaleUpperCase(),
						)
					) {
						updatedValue = newValue.toUpperCase();
					} else {
						toastNotification(
							`Sorry, we don't cover your area yet!`,
							"bg-warning",
						);
						return;
					}
				} else {
					toastNotification("Invalid Postcode", "bg-error text-white");
					return;
				}
			}

			if (!updatedValue) {
				toastNotification("Field is blank", "bg-warning");
				return;
			}

			const updateData = {
				[field]: updatedValue,
			};

			await updateUser(loggedInUser.id, loggedInUserToken, updateData);

			setLoggedInUser((prevUser) => ({
				...prevUser,
				...updateData,
			}));

			const storedUserInfo = localStorage.getItem("loggedInUserInfo");
			if (storedUserInfo) {
				const parsedUserInfo = JSON.parse(storedUserInfo);
				parsedUserInfo.user = {
					...parsedUserInfo.user,
					...updateData,
				};

				localStorage.setItem(
					"loggedInUserInfo",
					JSON.stringify(parsedUserInfo),
				);
			}
		} catch (error) {
			console.error("Error updating user field:", error);
			toastNotification("Error updating user field", "bg-error text-white");
		}
	};

	return (
		<div className="w-full flex flex-col gap-4 min-h-[60vh] p-4">
			<h1 className="text-3xl font-bold">{`Welcome back, ${
				loggedInUser?.fullName?.split(" ")[0]
			} 👋`}</h1>

			{!checkUserHasAnAddress(loggedInUser) ? (
				<div className="flex gap-2 justify-center items-center">
					<AddAddressModal />

					<div
						className="tooltip tooltip-left"
						data-tip="You need to set an address before you can place an order"
					>
						<button className="outline outline-error text-error w-8 h-8 rounded-full animate-pulse">
							i
						</button>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-4">
					{/* YOUR PROFILE */}
					<div
						className={`collapse collapse-arrow bg-base-200 ${
							isSectionActive("Your Details") ? "bg-primary text-white" : ""
						}`}
					>
						<input
							type="radio"
							name="my-accordion-3"
							checked={isSectionActive("Your Details")}
							onChange={() => toggleAccordion("Your Details")}
						/>
						<div className="collapse-title text-xl font-medium">
							<h3 className="text-xl font-semibold">Your Details</h3>
						</div>
						<div className="collapse-content">
							<div className="flex flex-col gap-2 mt-10">
								<div className="flex flex-col">
									<EditableField
										label="Full Name"
										value={loggedInUser.fullName}
										onSave={(newValue: string | undefined) =>
											handleSaveField("fullName", newValue)
										}
									/>
									<EditableField
										label="House Name/No"
										value={loggedInUser.houseNo}
										onSave={(newValue: string | undefined) =>
											handleSaveField("houseNo", newValue)
										}
									/>
									<EditableField
										label="Street"
										value={loggedInUser.street}
										onSave={(newValue: string | undefined) =>
											handleSaveField("street", newValue)
										}
									/>
									<EditableField
										label="City"
										value={loggedInUser.city}
										onSave={(newValue: string | undefined) =>
											handleSaveField("city", newValue)
										}
									/>
									<EditableField
										label="Postcode"
										value={loggedInUser.postcode}
										onSave={(newValue: string | undefined) =>
											handleSaveField("postcode", newValue)
										}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* YOUR STATS */}
					{/* <YourStats
            isSectionActive={isSectionActive}
            toggleAccordion={toggleAccordion}
            usersOrderData={usersOrderData}
          /> */}
				</div>
			)}
		</div>
	);
};

export default Profile;
