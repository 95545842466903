interface OrderTableProps {
	basketItems: {
		id: number;
		title: string;
		quantity: number;
		price: number;
	}[];
	deliveryMethod: {
		price: number;
	};
	totalPrice: number;
}

const OrderTable = ({
	basketItems,
	deliveryMethod,
	totalPrice,
}: OrderTableProps) => {
	return (
		<div className="overflow-x-auto">
			<table className="table">
				<thead>
					<tr>
						<th>Item</th>
						<th>Quantity</th>
						<th>Price</th>
					</tr>
				</thead>
				<tbody>
					{basketItems.map((item) => (
						<tr key={item.id}>
							<td>{item.title}</td>
							<td>{item.quantity}</td>
							<td>£{(item.quantity * item.price).toFixed(2)}</td>
						</tr>
					))}
					<tr>
						<td>Delivery Charge</td>
						<td />
						<td>£{deliveryMethod.price.toFixed(2)}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td className="text-lg">Total:</td>
						<td />
						<td className="text-lg">£{totalPrice.toFixed(2)}</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default OrderTable;
