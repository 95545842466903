const EmptyOrdersMessage = () => {
	return (
		<div className="mt-10 h-screen">
			<h1 className="text-lg font-semibold">
				When you make an order, they will appear here!
			</h1>
		</div>
	);
};

export default EmptyOrdersMessage;
