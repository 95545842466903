import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import MapComponent from "../components/Map";
import { AnimatedBubbles } from "@/components/AnimatedBubbles";

const Home = () => {
	return (
		<div className="flex flex-col justify-center items-center -mt-20">
			<Helmet>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content="Laundro - Laundry to go. The online laundrette | We offer free collection and delivery of your laundry for Washing, Drying and Ironing services!"
				/>
				<title>Laundry delivery | Online laundrette | Laundro</title>
				<link rel="canonical" href="https://laundro.co.uk/" />

				<meta
					property="og:title"
					content="Laundro - Laundry delivery service"
				/>
				<meta
					property="og:description"
					content="Free collection and delivery for washing, drying, and ironing services."
				/>
				<meta property="og:url" content="https://laundro.co.uk/" />

				<script type="application/ld+json">
					{`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Laundro",
            "url": "https://laundro.co.uk",
            "logo": "https://laundro.co.uk/logo.png",
            "description": "We offer free collection and delivery of laundry services including washing, drying, and ironing.",
            "sameAs": ["https://www.facebook.com/laundro", "https://www.instagram.com/laundro"]
          }`}
				</script>
			</Helmet>

			<div className="w-screen p-10 flex flex-col min-h-screen bg-gradient-to-br from-primary to-accent text-white overflow-hidden">
				<AnimatedBubbles />

				<main className="flex-1 flex items-center relative">
					<div className="container px-4 md:px-6 flex flex-col lg:flex-row items-center gap-12">
						<div className="space-y-4 text-center lg:text-left lg:w-1/2">
							<h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl/none xl:text-8xl/none">
								Laundry,{" "}
								<span className="text-accent inline-block transform hover:scale-105 transition-transform">
									Revolutionised
								</span>
							</h1>
							<p className="mx-auto lg:mx-0 max-w-[600px] text-gray-200 md:text-xl">
								{`Experience the future of clean. Schedule a pickup, we'll work
								our magic, and deliver freshness to your doorstep.`}
							</p>
							<div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
								<Link to={"/laundry/services"} className="btn btn-primary">
									Schedule Collection
								</Link>
								<Link to={"/about"} className="btn btn-outline text-white">
									Learn More
								</Link>
							</div>
						</div>
						<div className="lg:w-1/2 flex justify-center">
							<div className="relative">
								<div className="absolute inset-0 bg-blue-300 rounded-full filter blur-3xl opacity-30 animate-pulse" />
								<div className="relative bg-white rounded-3xl p-8 shadow-2xl transform hover:scale-105 transition-transform">
									<div className="grid grid-cols-2 gap-8">
										{[
											{ icon: "Sparkles", text: "Washing" },
											{ icon: "Clock", text: "Drying" },
											{ icon: "WashingMachine", text: "Ironing" },
											{ icon: "Truck", text: "Free Delivery" },
										].map((item, index) => (
											<div
												key={index}
												className="flex flex-col items-center text-center"
											>
												{/* <div className="bg-blue-100 p-3 rounded-full mb-3">
													<item.icon className="h-8 w-8 text-blue-600" />
												</div> */}
												<div className="bg-blue-100 p-3 rounded-full mb-3">
													<div className="h-8 w-8 text-blue-600" />
												</div>
												<span className="text-sm font-medium text-gray-600">
													{item.text}
												</span>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>

			<div className="p-4 sm:p-0 w-full">
				<MapComponent width="200%" />
			</div>
		</div>
	);
};

export default Home;
