import { useEffect, useRef, useState } from "react";

export function AnimatedBubbles() {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		const updateDimensions = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		updateDimensions();
		window.addEventListener("resize", updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	}, []);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		canvas.width = dimensions.width;
		canvas.height = dimensions.height;

		const isMobile = dimensions.width <= 768;

		class Bubble {
			x: number;
			y: number;
			size: number;
			speedY: number;

			constructor() {
				this.x = Math.random() * (canvas?.width ?? 0);
				this.y = (canvas?.height ?? 0) + Math.random() * 100;
				this.size = isMobile ? Math.random() * 5 + 1 : Math.random() * 8 + 2;
				this.speedY = isMobile
					? Math.random() * 1.5 + 0.5
					: Math.random() * 2 + 1;
			}
			update() {
				this.y -= this.speedY;
				if (this.y + this.size < 0) {
					this.y = (canvas?.height ?? 0) + this.size;
				}
			}
			draw() {
				if (!ctx) return;
				ctx.beginPath();
				ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
				ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
				ctx.fill();
			}
		}

		const bubbleCount = isMobile ? 25 : 50;
		const bubbles: Bubble[] = [];
		for (let i = 0; i < bubbleCount; i++) {
			bubbles.push(new Bubble());
		}

		function animate() {
			if (!ctx || !canvas) return;
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			// biome-ignore lint/complexity/noForEach: <explanation>
			bubbles.forEach((bubble) => {
				bubble.update();
				bubble.draw();
			});
			requestAnimationFrame(animate);
		}

		animate();
	}, [dimensions]);

	return (
		<canvas ref={canvasRef} className="absolute inset-0 pointer-events-none" />
	);
}
