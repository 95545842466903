import { Button } from "@/components/ui/button";
import type { UsersOrderData } from "@/types";
import OrderTable from "./OrderTable";
import OrderNotes from "./OrderNotes";

interface OrderCardProps {
	order: UsersOrderData;
	onCancelOrder?: (orderId: number, latestChargeId: string) => Promise<void>;
	isCancelled: boolean;
}

const OrderCard = ({ order, onCancelOrder, isCancelled }: OrderCardProps) => {
	return (
		<div className="card bg-base-200 px-4 md:px-10 py-2">
			<div className="flex justify-between items-center gap-6">
				<p>Ordered On: {new Date(order.createdAt).toLocaleDateString()}</p>
				<p>
					Collection Date: {new Date(order.collectionDate).toLocaleDateString()}
				</p>
			</div>

			<OrderTable
				basketItems={order.basketItems}
				deliveryMethod={order.deliveryMethod}
				totalPrice={order.totalPrice}
			/>

			{order.notes && <OrderNotes notes={order.notes} />}

			{!isCancelled && onCancelOrder && (
				<Button
					className="btn btn-primary"
					onClick={() => onCancelOrder(order.id, order.latestChargeId)}
				>
					Cancel This Order
				</Button>
			)}
		</div>
	);
};

export default OrderCard;
