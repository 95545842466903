import type { SVGProps } from "react";
import type { JSX } from "react/jsx-runtime";

const LaundroFullLogoSVG = (
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
	return (
		<svg
			aria-labelledby="laundroLogoFull"
			id="Layer_2"
			data-name="Layer 2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 670.99 147.83"
			className="w-[100px] h-10"
			{...props}
		>
			<title id="laundroLogoFull">Laundro Icon Full</title>
			<defs>
				<style>{".cls-1 { stroke-width: 0px; }"}</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<g>
					<path
						className="cls-1"
						d="m40.29,131.09h0c0,9.25-10.3,16.74-19.54,16.74h0c-11.46,0-20.75-9.29-20.75-20.75V.03h24.23l-.37,117.56c-.31,11,2.67,14.93,16.44,13.49h0Z"
					/>
					<path
						className="cls-1"
						d="m194.67,147.78c-8.08,0-15.04-1.65-20.9-4.95-5.85-3.3-10.33-8.28-13.43-14.94-3.1-6.66-4.64-14.84-4.64-24.53v-59.76h24.23v57.13c0,8.75,1.85,15.38,5.55,19.89,3.7,4.51,9.32,6.76,16.86,6.76,4.71,0,8.98-1.18,12.82-3.53,3.84-2.35,6.86-5.65,9.09-9.89,2.22-4.24,3.33-9.52,3.33-15.85v-54.51h24.23v101.75h-21.4v-16.96c-2.97,5.92-9.19,10.63-15.04,14.13-5.85,3.5-12.75,5.25-20.69,5.25Z"
					/>
					<path
						className="cls-1"
						d="m268.63,145.36V43.61h21.4l.02,16.96c3.1-5.92,9.13-10.63,14.92-14.13,5.78-3.5,12.65-5.25,20.59-5.25s15.21,1.72,21,5.15c5.78,3.43,10.22,8.45,13.32,15.04,3.09,6.6,4.64,14.81,4.64,24.63v59.35h-24.02v-56.93c0-8.75-1.89-15.41-5.65-19.99-3.77-4.57-9.36-6.86-16.76-6.86-4.85,0-9.15,1.18-12.92,3.53-3.77,2.36-6.76,5.69-8.98,9.99-2.22,4.31-3.33,9.62-3.33,15.95v54.31h-24.23Z"
					/>
					<path
						className="cls-1"
						d="m427,147.78c-9.42,0-17.8-2.32-25.13-6.96-7.34-4.64-13.06-10.97-17.16-18.98-4.11-8.01-6.16-17.13-6.16-27.36s2.09-19.55,6.26-27.56c4.17-8.01,9.99-14.3,17.46-18.88,7.47-4.57,15.91-6.86,25.34-6.86,7.54,0,14.16,1.45,19.89,4.34,5.72,2.9,10.26,6.96,13.63,12.21V0h24.23v145.36h-21.6v-14.94c-2.42,3.07-7.2,6.13-10.3,8.68-3.1,2.56-6.83,4.64-11.2,6.26-4.38,1.62-9.46,2.42-15.24,2.42Zm5.25-21c5.92,0,11.07-1.38,15.44-4.14,4.37-2.76,7.74-6.56,10.1-11.41,2.35-4.85,3.53-10.43,3.53-16.76s-1.18-11.91-3.53-16.76c-2.36-4.85-5.72-8.65-10.1-11.41-4.38-2.76-9.52-4.14-15.44-4.14-5.52,0-10.5,1.35-14.94,4.04-4.44,2.69-7.87,6.5-10.3,11.41-2.42,4.91-3.63,10.53-3.63,16.86s1.21,12.08,3.63,16.86c2.42,4.78,5.85,8.55,10.3,11.3,4.44,2.76,9.42,4.14,14.94,4.14Z"
					/>
					<path
						className="cls-1"
						d="m502.17,145.36V43.61h21.6v18.98c2.57-4.44,7.6-8.24,11.1-11.41,3.5-3.16,7.74-5.62,12.72-7.37,4.98-1.75,10.5-2.62,16.56-2.62v25.44h-8.68c-4.04,0-7.88.51-11.51,1.51-3.63,1.01-6.76,2.66-9.39,4.95-2.63,2.29-4.64,5.38-6.06,9.29-1.41,3.9-2.12,8.68-2.12,14.33v48.65h-24.23Z"
					/>
					<path
						className="cls-1"
						d="m619.71,147.78c-9.69,0-18.41-2.22-26.14-6.66-7.74-4.44-13.8-10.7-18.17-18.77-4.38-8.08-6.56-17.29-6.56-27.66s2.22-19.99,6.66-28.06c4.44-8.08,10.53-14.33,18.27-18.77,7.74-4.44,16.45-6.66,26.14-6.66s18.37,2.22,26.04,6.66c7.67,4.44,13.76,10.67,18.27,18.67,4.51,8.01,6.76,17.33,6.76,27.96s-2.29,19.79-6.86,27.86c-4.58,8.08-10.7,14.33-18.37,18.77-7.67,4.44-16.35,6.66-26.04,6.66Zm0-20.79c4.84,0,9.25-1.24,13.22-3.73,3.97-2.49,7.17-6.16,9.59-11,2.42-4.85,3.63-10.77,3.63-17.77s-1.18-13.12-3.53-17.97c-2.36-4.85-5.52-8.48-9.49-10.9-3.97-2.42-8.31-3.63-13.02-3.63s-9.29,1.21-13.32,3.63c-4.04,2.42-7.27,6.06-9.69,10.9-2.42,4.84-3.63,10.84-3.63,17.97s1.21,12.92,3.63,17.77c2.42,4.85,5.62,8.51,9.59,11,3.97,2.49,8.31,3.73,13.02,3.73Z"
					/>
					<path
						className="cls-1"
						d="m82.56,147.78c-8.21,0-15.11-1.41-20.69-4.24-5.59-2.83-9.73-6.59-12.42-11.31-2.69-4.71-4.04-9.89-4.04-15.55,0-6.32,1.65-11.84,4.95-16.55,3.3-4.71,8.18-8.38,14.64-11,6.46-2.62,14.47-3.94,24.02-3.94h25.64c0-5.38-.78-9.86-2.32-13.43-1.55-3.56-3.87-6.26-6.97-8.08-3.1-1.82-7.13-2.73-12.11-2.73-5.52,0-10.23,1.28-14.13,3.84-3.9,2.56-6.33,6.39-7.27,11.51h-23.82c.81-7.27,3.26-13.53,7.37-18.77,4.1-5.25,9.49-9.29,16.15-12.11,6.66-2.83,13.89-4.24,21.7-4.24,9.69,0,17.93,1.65,24.73,4.95,6.8,3.3,11.98,7.98,15.55,14.03,3.57,6.06,5.35,13.46,5.35,22.21v62.99h-20.79l-.02-16.15c-1.95,2.47-5.46,5.15-7.55,7.37-2.09,2.22-4.51,4.17-7.27,5.85-2.76,1.68-5.82,2.99-9.18,3.94-3.37.94-7.2,1.41-11.51,1.41Zm5.45-19.18c3.9,0,7.4-.71,10.5-2.12,3.09-1.41,5.72-3.43,7.87-6.06,2.15-2.62,3.87-5.58,5.15-8.88,1.28-3.3,2.05-6.76,2.32-10.4v-.4h-22.21c-4.85,0-8.78.61-11.81,1.82-3.03,1.21-5.28,2.86-6.76,4.95-1.48,2.09-2.22,4.54-2.22,7.37s.71,5.45,2.12,7.47c1.41,2.02,3.43,3.57,6.06,4.64,2.62,1.08,5.62,1.61,8.98,1.61Z"
					/>
				</g>
			</g>
		</svg>
	);
};
export default LaundroFullLogoSVG;
