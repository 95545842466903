import { Link } from "react-router-dom";
import ShoppingCart from "./ShoppingCart";
import Avatar from "./Avatar";
import { useStateContext } from "../context/ContextProvider";
import { isUserAnAdmin } from "../utils/isUserAnAdmin";
import { endpoints } from "../constants/endpoints";
import LaundroFullLogoSVG from "../assets/SVG/LaundroFullLogoSVG";
import { NavigationMenu, NavigationMenuList } from "./ui/navigation-menu";
import { useScroll } from "@/hooks/useScroll";

const Navbar = () => {
	const { loggedInUser } = useStateContext()!;
	const hasScrolled = useScroll();

	return (
		<nav
			className={`navbar fixed top-0 left-0 w-full py-2 px-4 z-50 transition-all duration-300 ${
				hasScrolled ? "bg-base-200 shadow-md opacity-90" : "bg-transparent "
			}`}
		>
			<div className="navbar-start">
				<Link
					to="/"
					className="btn btn-ghost normal-case text-xl hover:bg-transparent"
				>
					<LaundroFullLogoSVG />
				</Link>
			</div>

			{/* Center Links */}
			<div className="navbar-center hidden lg:flex">
				{isUserAnAdmin(loggedInUser) ? (
					<ul className="menu menu-horizontal px-1">
						<li>
							<Link to={"/dashboard"}>Dashboard</Link>
						</li>
					</ul>
				) : (
					<ul className="menu menu-horizontal px-1">
						{endpoints.map((endpoint) => (
							<li key={endpoint.endpoint}>
								<Link
									to={endpoint.endpoint}
									target={endpoint.page === "Blog" ? "_blank" : ""}
								>
									{endpoint.page === "Home" ? null : endpoint.page}
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>

			<div className="navbar-end">
				<ShoppingCart />

				<NavigationMenu>
					<NavigationMenuList>
						<Avatar />
					</NavigationMenuList>
				</NavigationMenu>

				<Link to="/laundry/services" className="btn btn-primary ml-4">
					Book Now
				</Link>
			</div>
		</nav>
	);
};

export default Navbar;
