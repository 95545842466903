import type { SVGProps } from "react";
import type { JSX } from "react/jsx-runtime";

const WasherIconSVG = (
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
	return (
		<svg
			aria-labelledby="washerIcon"
			id="Layer_2"
			data-name="Layer 2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 172.12 206.02"
			className="w-10 h-10 sm:w-14 sm:h-14"
			{...props}
		>
			<title id="washerIcon">Washer Icon</title>
			<g id="Layer_1-2" data-name="Layer 1">
				<g>
					<path
						className="cls-1"
						d="m152.24,0H19.87C8.94,0,0,8.94,0,19.87v34.92c0,1.86,1.51,3.37,3.37,3.37h159.26v127.99c0,5.71-4.65,10.38-10.39,10.38H19.87c-5.73,0-10.38-4.67-10.38-10.38v-108.25c0-2.62-2.12-4.75-4.75-4.75h0C2.12,73.15,0,75.27,0,77.9v108.25c0,10.93,8.94,19.87,19.87,19.87h132.37c10.98,0,19.88-8.92,19.88-19.87V19.87c0-10.95-8.9-19.87-19.88-19.87ZM9.49,48.67v-28.8c0-5.71,4.65-10.38,10.38-10.38h132.37c5.74,0,10.39,4.67,10.39,10.38v28.8H9.49Z"
					/>
					<rect
						className="cls-1"
						x={29.38}
						y={24.03}
						width={27.11}
						height={9.49}
						rx={2.54}
						ry={2.54}
					/>
					<path
						className="cls-1"
						d="m86.63,77.78c-26.74,0-48.49,21.75-48.49,48.49s21.75,48.48,48.49,48.48,48.48-21.75,48.48-48.48-21.75-48.49-48.48-48.49Zm2.83,49.29c-13.56-10.09-27.53-11.65-41.64-4.69,1.96-19.69,18.61-35.11,38.81-35.11,21.5,0,38.99,17.49,38.99,39,0,.59,0,1.17-.04,1.75-14.42,7.2-25.34,6.85-36.12-.95Z"
					/>
					<circle className="cls-1" cx={111.44} cy={28.78} r={7.09} />
					<circle className="cls-1" cx={134.82} cy={28.78} r={7.09} />
				</g>
			</g>
		</svg>
	);
};

export default WasherIconSVG;
