import React, { useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { baseLocation } from "../data/baseLocation";
import type { BaseLocation } from "../types";

function MapComponent({ width }: { width?: string }) {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
	});

	const containerStyle = {
		width: width ?? "200%",
		height: "700px",
	};

	const center: BaseLocation = baseLocation;

	const radiusMeters = 8046.72; // 5 miles in meters

	const [map, setMap] = React.useState<google.maps.Map | null>(null);
	const [, setCircle] = React.useState<google.maps.Circle | undefined>(
		undefined,
	);

	useEffect(() => {
		if (map) {
			const circleOptions = {
				strokeColor: "#FF0000",
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: "#FF0000",
				fillOpacity: 0.2,
				map,
				center,
				radius: radiusMeters,
			};

			const circleInstance = new window.google.maps.Circle(circleOptions);
			setCircle(circleInstance);
		}
	}, [map]);

	const onLoad = React.useCallback(function callback(map: google.maps.Map) {
		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback() {
		setMap(null);
	}, []);

	return isLoaded ? (
		<div className="w-1/2">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={11}
				options={{
					mapTypeControl: false, // Disable map type control (includes Street View and Satellite View options)
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{/* Child components, such as markers, info windows, etc. */}
			</GoogleMap>
		</div>
	) : null;
}

export default React.memo(MapComponent);
